<template>
  <div class="Instructions">
    <h1 name="crea">ISTRUZIONI PER LA <br /> CREAZIONE DI UN ACCOUNT EOS</h1>
    <p>Creare un account EOS richiede una serie di passi abbastanza semplici (se si conosce l&rsquo;inglese), attraverso il sito <a href="https://eos-account-creator.com/">https://eos-account-creator.com/</a></p>
    <p :style="{marginTop: '50px'}">Cosa ci serve:</p>
    <ol start="1" type="1">
      <li>Decidere il nome del proprio account EOS (fatto da 12 caratteri). Questo sar&agrave; per sempre il vostro account, quindi scegliete bene&hellip;</li>
      <li>Una carta di credito o altra moneta crypto (al momento costa circa 6 euro)</li>
    </ol>
    <h2>PASSO 1: aprite eos-account-creator.com</h2>
    <p>Come primo passo, aprite questo sito: <a href="https://eos-account-creator.com/">https://eos-account-creator.com/</a></p>
    <p>Vedrete questa pagina:</p>
    <p><img src="/eos/image002.jpg" alt="" width="468" height="300" border="0" /></p>
    <p>Cliccate su CREATE EOS ACCOUNT (bottone blu)</p>
    <h2>PASSO 2: scegliete il vostro nome account</h2>
    <p>A questo punto, dovete scegliere il nome del vostro account. Deve essere di 12 caratteri. Tenete conto che vi rimarr&agrave; per sempre, per cui scegliete bene&hellip;</p>
    <p><img src="/eos/image004.jpg" alt="" width="468" height="300" border="0" /></p>
    <p>Poi cliccate su CONTINUE</p>
    <h2>PASSO 3: create la chiave privata e pubblica</h2>
    <p>La chiave privata vi serve per essere gli unici proprietari del vostro account. E&rsquo; un insieme di caratteri che dovete conservare gelosamente perch&eacute; rappresentano l&rsquo;unico modo per accedere al vostro account. Se perdete la chiave privata, non avrete mai pi&ugrave; la possibilit&agrave; di accedere al vostro account.</p>
    <p>La chiave privata (e la sua sorella pubblica) e&rsquo; FONDAMENTALE. Dovete conservarla da qualche parte inaccessibile.</p>
    <p><img src="/eos/image006.jpg" alt="" width="468" height="300" border="0" /></p>
    <p>Ci sono tanti modi per creare una coppia di chiavi privata e pubblica. La cosa piu&rsquo; semplice &egrave; cliccare sul link &ldquo;Simple javascript generator&rdquo; a sinistra, che vi porta a una pagina con una coppia di chiavi pubblica e privata, tipo la seguente:</p>
    <p><img src="/eos/image008.jpg" alt="" width="468" height="300" border="0" /></p>
    <p>A questo punto, copiatevi la Public Key (chiave pubblica) e la Private Key (chiave privata) in qualche posto sicurissimo. Magari stampate la pagina per sicurezza.</p>
    <p>Di nuovo, se perdete la chiave privata, non avrete mai piu&rsquo; la possibilita&rsquo; di accedere al vostro account EOS e perderete l&rsquo;account e gli NFT associati con esso.</p>
    <p>Copiate la chiave PUBBLICA che vi serve per il prossimo passo (facendo copia e incolla dalla pagina del browser).</p>
    <h2>PASSO 4: inserite la vostra chiave pubblica</h2>
    <p>A questo punto, copiate e incollate la vostra chiave pubblica dentro la pagina (due volte, la STESSA chiave PUBBLICA, sia per Owner Public Key che per Active Public Key).</p>
    <p><img src="/eos/image010.jpg" alt="" width="468" height="300" border="0" /></p>
    <p>Fate il clic sulla spunta di &ldquo;I have saved and stored my private key in a safe place&rdquo; e, di nuovo, assicuratevi di aver salvato la vostra chiave private in un posto sicuro. E poi cliccate su CONTINUE (bottone blu)</p>
    <h2>PASSO 5: inserite la vostra carta di credito (o altra crypto currency)</h2>
    <p>Ultimo passo, mettete la carta di credito e pagate. A questo punto sarete titolari di un account EOS. Complimenti!</p>
    <p><img src="/eos/image012.jpg" alt="" width="468" height="300" border="0" /></p>
    <h1 id="proprietario">Invia il nome tuo account EOS appena creato ad A-LIVE</h1>
    <p>per diventare proprietari a tutti gli effetti del vostro NFT, sarà necessario mandare una email con il vostro account EOS a
      <a href="mailto:info@alivemusic.tv">info@alivemusic.tv</a> (usando l'email con cui vi siete registrati ad A-LIVE)</p>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

const VueScrollTo = require('vue-scrollto');
const options = {
  container: 'body',
  easing: 'ease-in',
  lazy: false,
  offset: -60,
  force: true,
  cancelable: true,
  // eslint-disable-next-line no-unused-vars
  onStart: function(element) {
    // scrolling started
  },
  // eslint-disable-next-line no-unused-vars
  onDone: function(element) {
    // scrolling is done
  },
  onCancel: function() {
    // scrolling has been interrupted
  },
  x: false,
  y: true
}
export default {
  name: 'eos',
  props: ['target'],
  methods: {
    ...mapMutations(['appReady']),
    scrollTo(place) {
      VueScrollTo.scrollTo(place, 0, options)
    },
  },
  mounted() {
    this.appReady(true);
    if (this.target) {
      this.scrollTo(`#${this.target}`);
    }
  },
};
</script>

<style lang="scss" scoped>
 .Instructions {
   padding: 40px 20px 200px 20px;
   color: white;
   max-width: 1000px;
   margin: 0 auto;
   #proprietario {
     margin-top: 150px;
   }
   h1 {
     margin: 20px 0;
     font-size: 35px;
   }
   h2 {
     margin: 80px 0 20px 0;
     font-size: 30px;
   }
   img {
     margin: 15px 0;
   }
   p {
     line-height: 20px;
     margin-bottom: 10px;
   }
   ol {
     padding-left: 20px;
     padding-bottom: 20px;
     li {
       padding-bottom: 10px;
     }
   }
 }
</style>
